"use strict";
/**
 * IMAGE_EXTENSIONS are the extensions associated with supported images.
 */
// const IMAGE_EXTENSIONS = /\.(jpe?g|gif|png|webp)$/i;
Object.defineProperty(exports, "__esModule", { value: true });
function validateImagePathname(pathname) {
    return true; // IMAGE_EXTENSIONS.test(pathname);
}
exports.default = validateImagePathname;
